<template>
    <div id="login-page">
        <div id="billboard">
            <div class="logo-wrapper">
                <img :src="getLogoUrl()" class="img-fluid" alt="" />
            </div>
            <div class="baseline-wrapper">
                <img :src="getBaselineUrl()" class="img-fluid" alt="" />
            </div>
        </div>
        <b-container id="login-form" class="text-center">
            <b-spinner
                v-if="load"
                type="grow"
                variant="danger"
                class="mt-5"
            ></b-spinner>
            <div v-else>
                <b-form
                    novalidate
                    class="needs-validation mt-0 mb-3 w-100"
                    autocomplete="off"
                    v-if="!success"
                >
                    <b-form-row class="justify-content-center">
                        <b-col md="6" lg="8" xl="8">
                            <b-form-group>
                                <b-form-select
                                    class="form-control"
                                    :options="optionsMode"
                                    v-model="mode"
                                ></b-form-select>
                            </b-form-group>
                            <div v-if="mode === 'login'">
                                <b-form-group>
                                    <h6 class="text-white text-left">
                                        Votre identifiant par défaut correspond
                                        à votre numéro de mobile.
                                    </h6>
                                </b-form-group>
                            </div>
                            <div v-if="mode !== 'login'">
                                <div v-if="step === 1">
                                    <b-form-group>
                                        <h6 class="text-left text-white">
                                            Identifiez-vous avec votre numéro de
                                            téléphone :
                                        </h6>
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-input
                                            class="form-control border-0"
                                            type="text"
                                            placeholder="Téléphone"
                                            v-model="form.telephone"
                                            :state="formValidation.telephone"
                                            aria-invalid="tel-error"
                                            required
                                        />
                                        <b-form-invalid-feedback id="tel-error">
                                            Veuillez saisir votre numéro de
                                            téléphone.
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                                <div v-if="step === 2">
                                    <b-form-group>
                                        <h6 class="text-white text-left">
                                            Renseignez le code de vérification
                                            et votre nouveau mot de passe :
                                        </h6>
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-input
                                            class="form-control border-0"
                                            type="text"
                                            placeholder="Code de vérification"
                                            v-model="form.code_sms"
                                            required
                                        />
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-input
                                            class="form-control border-0"
                                            type="password"
                                            autocomplete="new-password"
                                            placeholder="Nouveau mot de passe"
                                            v-model="form.password"
                                            required
                                        />
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-input
                                            class="form-control border-0"
                                            type="password"
                                            autocomplete="new-password"
                                            placeholder="Confirmation du mot de passe"
                                            v-model="form.password_confirmation"
                                            required
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <button
                                class="align-items-center btn btn-lg btn-danger d-flex justify-content-center w-100"
                                @click="validate"
                                v-if="mode !== 'login'"
                            >
                                <span class="align-items-center d-flex"
                                    ><i class="fa-sign-in-alt fas mr-3"></i>
                                    Confirmer</span
                                >
                            </button>
                        </b-col>
                    </b-form-row>
                </b-form>
                <b-col
                    sm="12 text-white text-center mt-3"
                    v-if="!load && success"
                >
                    <p>
                        Votre compte a bien été enregistré. Vous pouvez dès à
                        présent vous connecter à l'application.
                    </p>
                    <p>
                        Vous serez rediriger dans 10 secondes vers le formulaire
                        de connexion ou cliquer sur le lien suivant:
                    </p>
                </b-col>
                <div class="w-100 text-center mt-3">
                    <router-link
                        class="text-white"
                        :class="{ 'btn btn-danger': success }"
                        to="/login"
                    >
                        Se connecter</router-link
                    >
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
import authenticationService from "../../services/authenticationService";
import router from "../../router";

export default {
    name: "forgotten-password",
    data() {
        return {
            mode: "login",
            step: 1,
            success: false,
            load: false,
            optionsMode: [
                { text: "Identifiant oublié", value: "login" },
                { text: "Mot de passe oublié", value: "password" },
            ],
            form: {
                telephone: "",
                captcha: "",
                code_sms: "",
                password: "",
                password_confirmation: "",
            },
            formValidation: {
                telephone: true,
                code_sms: true,
                password: true,
                password_confirmation: true,
            },
        };
    },
    mounted() {
        this.recaptcha();
    },
    methods: {
        async recaptcha() {
            console.log(1);
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded();
            console.log(2);
            // Execute reCAPTCHA with action.
            const token = await this.$recaptcha("changePassword");
            console.log(3);
            this.form.captcha = token;
        },
        getLogoUrl() {
            if (!location.host.includes("family")) {
               return "/img/logo.png";
            } else {
                return "/img/gfs_logo_2.png";
            }
        },
        getBaselineUrl() {
            if (!location.host.includes("family")) {
                return "/img/baseline.png";
            } else {
                return "/img/gfs_baseline.png";
            }
        },
        validateForm() {
            this.formValidation.telephone = this.form.telephone.length > 0;
            this.formValidation.password = this.form.password.length > 0;
            this.formValidation.password_confirmation =
                this.form.password_confirmation.length > 0 &&
                this.form.password_confirmation === this.form.password;
        },
        validate(event) {
            event.preventDefault();
            this.validateForm();
            if (this.step === 1) {
                if (
                    this.formValidation.telephone &&
                    this.form.captcha.length > 0
                ) {
                    authenticationService
                        .resendVerificationCode(this.form)
                        .then((response) => {
                            if (response.data.etat === "OK") {
                                this.$bvToast.toast(
                                    "Un SMS vous a été envoyé",
                                    {
                                        title: "Succès",
                                        autoHideDelay: 5000,
                                        variant: "success",
                                    }
                                );
                                this.step = 2;
                            } else {
                                this.$bvToast.toast("Une erreur est survenue", {
                                    title: "Erreur 3005",
                                    autoHideDelay: 5000,
                                    variant: "danger",
                                });
                            }
                        });
                } else {
                    this.$bvToast.toast("Une erreur est survenue", {
                        title: "Erreur captcha",
                        autoHideDelay: 5000,
                        variant: "danger",
                    });
                }
            } else {
                this.validateForm();
                if (
                    this.formValidation.password &&
                    this.formValidation.password_confirmation &&
                    this.formValidation.code_sms
                ) {
                    authenticationService
                        .changePassword(this.form)
                        .then((response) => {
                            if (response.data.etat === "OK") {
                                this.success = true;
                                setTimeout(() => {
                                    router.push({ name: "login" });
                                }, 10000);
                            } else {
                                this.$bvToast.toast("Une erreur est survenue", {
                                    title: "Erreur 3006",
                                    autoHideDelay: 5000,
                                    variant: "danger",
                                });
                            }
                        })
                        .catch((e) => {
                            this.$rollbar.info("Password reset error", {
                                label: "Password reset error",
                                username: this.form.telephone,
                                useragent: navigator.userAgent,
                            });

                            console.log(e);
                            this.$bvToast.toast("Une erreur est survenue", {
                                title: "Erreur d'authentification",
                                autoHideDelay: 5000,
                                variant: "danger",
                            });
                        });
                }
            }
        },
    },
};
</script>

<style scoped>
#login-page #billboard {
    position: absolute;
    width: 100%;
    left: 0;
}
#login-page #login-form {
    margin-top: 15em;
}
@media (min-width: 992px) {
    #login-page #login-form {
        margin-top: 18em;
    }
}
</style>
